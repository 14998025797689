import { of } from 'rxjs'

import { Option } from '@shared/features/forms/models/forms.model'
import { FormGroup } from '@angular/forms'

export const plantasOptions = (form: FormGroup) => {
  const bu = form.controls['select-bu'].value

  const options: Option[] = [
    {
      label: '::Seleccionar::',
      value: '',
      selected: true,
      disabled: true
    }
  ]

  if (bu === 'COL') {
    options.push(
      ...[
        { label: 'BU COL', value: 'BU COL' },
        { label: 'Barranquilla', value: 'Barranquilla' },
        { label: 'Boyaca', value: 'Boyaca' },
        { label: 'Bucaramanga', value: 'Bucaramanga' },
        { label: 'Medellin', value: 'Medellin' },
        { label: 'Tocancipa', value: 'Tocancipa' },
        { label: 'Valle', value: 'Valle' }
      ]
    )
  } else if (bu === 'PEC') {
    options.push(
      ...[
        { label: 'BU PEC', value: 'BU PEC' },
        { label: 'Arequipa', value: 'Arequipa' },
        { label: 'Ate Beer', value: 'Ate Beer' },
        { label: 'Cusco', value: 'Cusco' },
        { label: 'Huarochiri Water', value: 'Huarochiri Water' },
        { label: 'Motupe Beer', value: 'Motupe Beer' },
        { label: 'San Juan', value: 'San Juan' },
        { label: 'Guayaquil Beer', value: 'Guayaquil Beer' },
        { label: 'Quito', value: 'Quito' }
      ]
    )
  } else if (bu === 'MEX Norte') {
    options.push(
      ...[
        { label: 'BU MEX', value: 'BU MEX' },
        { label: 'Zacatecas', value: 'Zacatecas' },
        { label: 'Guadalajara', value: 'Guadalajara' },
        { label: 'Torreon', value: 'Torreon' },
        { label: 'Mazatlan', value: 'Mazatlan' }
      ]
    )
  } else if (bu === 'MEX Sur') {
    options.push(
      ...[
        { label: 'BU MEX', value: 'BU MEX' },
        { label: 'Mexico APAN', value: 'Mexico APAN' },
        { label: 'Mexico Plant', value: 'Mexico Plant' },
        { label: 'Tuxtepec', value: 'Tuxtepec' },
        { label: 'Merida', value: 'Merida' }
      ]
    )
  } else if (bu === 'HES') {
    options.push(
      ...[
        { label: 'BU HES', value: 'BU HES' },
        { label: 'San Pedro Sula Beer', value: 'San Pedro Sula Beer' },
        { label: 'San Pedro Sula CSD', value: 'San Pedro Sula CSD' },
        { label: 'Ind La Constancia Beer', value: 'Ind La Constancia Beer' },
        { label: 'Ind La Constancia CSD', value: 'Ind La Constancia CSD' },
        { label: 'Ind La Constancia Water', value: 'Ind La Constancia Water' }
      ]
    )
  } else if (bu === 'CAC') {
    options.push(
      ...[
        { label: 'BU CAC', value: 'BU CAC' },
        { label: 'Holguin', value: 'Holguin' },
        { label: 'Hato Nuevo', value: 'Hato Nuevo' },
        { label: 'San Vincent', value: 'San Vincent' },
        { label: 'Santo Domingo', value: 'Santo Domingo' },
        { label: 'Zacapa', value: 'Zacapa' },
        { label: 'Panama', value: 'Panama' },
        { label: 'Barbados', value: 'Barbados' }
      ]
    )
  } else if (bu === 'Vertical Operations') {
    options.push(
      ...[
        { label: 'ZONA VOP', value: 'ZONA VOP' },
        { label: 'Malt Guayaquil', value: 'Malt Guayaquil' },
        { label: 'Malt Lima', value: 'Malt Lima' },
        { label: 'Malt Tibito', value: 'Malt Tibito' },
        { label: 'Malt Cartagena', value: 'Malt Cartagena' },
        { label: 'Malt Cebadas y Maltas', value: 'Malt Cebadas y Maltas' },
        { label: 'Malt Zacatecas', value: 'Malt Zacatecas' },
        { label: 'Label Indugral', value: 'Label Indugral' },
        { label: 'Crown Tocancipa', value: 'Crown Tocancipa' },
        { label: 'Crown Zacatecas', value: 'Crown Zacatecas' },
        { label: 'Can Zacatecas', value: 'Can Zacatecas' },
        { label: 'Glass Potosi', value: 'Glass Potosi' },
        { label: 'Glass Tierra Blanca', value: 'Glass Tierra Blanca' }
      ]
    )
  }

  return of(options)
}
