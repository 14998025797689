<button
  [class]="customClasses"
  [type]="type"
  [disabled]="isLoading || disabled"
  [ariaDisabled]="isLoading">
  @if (isLoading) {
    <span>Loading...</span>
  } @else {
    <ng-content></ng-content>
  }
</button>
